<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="overflow-hidden">
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="font-weight-bold" color="grey darken-2">
                            GENERAR REPORTE
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <span class="text-h6 green--text">Filtrar por</span>
                        <v-row class="mx-0">
                            <v-col cols="8" lg="8" md="12" sm="12">
                                <v-row>
                                    <v-col v-if="user.type_user !== 'local'" cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.municipality_id"
                                                :items="municipalities"
                                                item-text="descripcion"
                                                item-value="municipio_id"
                                                label="Municipio"
                                                prepend-icon="mdi-map-marker"
                                                single
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.territory_id"
                                                :items="territoriesFiltered"
                                                :readonly="user.type_user === 'local'"
                                                :clearable="user.type_user !== 'local'"
                                                item-text="descripcion"
                                                item-value="territorio_id"
                                                label="Territorio"
                                                prepend-icon="mdi-map-marker"
                                                single
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.sidewalk_id"
                                                :items="sidewalksFiltered"
                                                clearable
                                                item-text="descripcion"
                                                item-value="vereda_id"
                                                label="Comunidad o vereda"
                                                prepend-icon="mdi-map-marker"
                                                single
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.gender_id"
                                                :items="[{id: null, name: 'Todos'},{'id': 1, 'name': 'Femenino'}, { 'id': 2, 'name': 'Masculino'}]"
                                                item-text="name"
                                                item-value="id"
                                                label="Género"
                                                prepend-icon="mdi-map-marker"
                                                single
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12">
                                        <v-text-field
                                                v-model="report.range_age_min"
                                                label="Edad mínima"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12">
                                        <v-text-field
                                                v-model="report.range_age_max"
                                                label="Edad Máxima"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.study_level_id"
                                                :items="studyLevels"
                                                item-text="descripcion"
                                                item-value="nivelestudio_id"
                                                label="Nivel de estudio"
                                                prepend-icon="mdi-bookmark-box-multiple"
                                                single
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="4" md="4" sm="12">
                                        <v-autocomplete
                                                v-model="report.occupation_id"
                                                :items="activities"
                                                clearable
                                                item-text="descripcion"
                                                item-value="ocupacion_id"
                                                label="Ocupación Principal"
                                                prepend-icon="mdi-map-marker"
                                                single
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                    class="d-flex flex-column justify-end"
                                    cols="4"
                                    lg="4"
                                    md="12"
                                    sm="12"
                            >
                                <div>
                                    <v-btn
                                            class="mb-5"
                                            color="primary"
                                            dark
                                            rounded
                                            @click="generateReport()"
                                    >
                                        GENERAR REPORTE
                                        <v-icon>mdi-chart-line</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {reports} from "@/services/censusService";
import sweetalert from "sweetalert2";
import {mapState} from "vuex";
import {isEmpty} from "lodash";

export default {
    name: "Reports.vue",
    data() {
        return {
            municipalities: [],
            territories: [],
            sidewalks: [],
            studyLevels: [],
            activities: [],
            economicIncome: [],
            report: {
                territory_id: "",
                gender_id: "",
                range_age_min: "",
                range_age_max: "",
                study_level_id: "",
                occupation_id: "",
                municipality_id: "",
                sidewalk_id: "",
            }
        };
    },
    methods: {
        async generateReport() {
            try {
                this.showLoader();
                await reports(this.report);
                this.hideLoader();
                sweetalert.fire("Reporte generado correctamente", "", "success");
            } catch (err) {
                console.error(err);
                this.hideLoader();
                sweetalert.fire(
                    "Error",
                    "ha ocurrido un error al procesar la solicitud",
                    "error"
                );
            }
        },
        fillData() {
            this.showLoader();
            this.municipalities = this.dataSource.data.Municipio;
            this.territories = this.dataSource.data.Territorio;
            this.sidewalks = this.dataSource.data.Vereda;
            this.studyLevels = this.dataSource.data.Nivelestudio;
            this.activities = this.dataSource.data.Ocupacion;
            this.economicIncome = this.dataSource.data.Ingresoeconomico;
            this.studyLevels.unshift({descripcion: "Todos", nivelestudio_id: ""});
            this.hideLoader();
        },
    },
    async created() {
        try {
            if (!isEmpty(this.dataSource)) this.fillData();
            if (this.user.type_user === 'local') {

                if (this.user.territory_id) {
                    this.report.municipality_id = this.dataSource.data.Municipio
                        .find(item => {
                            return Boolean(item.municipio_territoriomunicipio.find(
                                territory => territory.territorio_id === this.user.territory_id
                            ))
                        })?.municipio_id || null;
                }

                this.report.territory_id = this.user.territory_id;
            }
        } catch (err) {
            console.error(err);
            this.hideLoader();
            sweetalert.fire(
                "Error",
                "ha ocurrido un error al procesar la solicitud",
                "error"
            );
        }
    },
    watch: {
        dataSource: function () {
            this.fillData();
        },
    },
    computed: {
        ...mapState(["dataSource", "user"]),
        territoriesFiltered() {
            const territories = this.$store.state.dataSource.data.Municipio.find(
                (item) => item.municipio_id === this.report.municipality_id
            )?.municipio_territoriomunicipio || [];

            return this.$store.state.dataSource.data.Territorio.filter(
                (item) => territories.map((item) => item.territorio_id).includes(item.territorio_id)
            );
        },
        sidewalksFiltered() {
            return this.sidewalks.filter(
                (sidewalk) => sidewalk.territorio === this.report.territory_id
            );
        },
    },
};
</script>

<style scoped></style>
